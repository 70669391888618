import { UpOutlined } from "@ant-design/icons";
import { Table, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import AddOnExpandable from "../AddOnExpandable";
import {
  addItemApi,
  getAddOnList,
  getItemInventory,
} from "../../../../apis/reservationApi";
import moment from "moment";
import { ReservationDetailRoute } from "../../../../url-routes/urlRoutes";
import { useNavigate } from "react-router-dom";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";

function ReservationAddOn(props) {
  const {
    addOnTypes,
    setAddOnTypes,
    setUpdateAddOn,
    updateAddOn,
    section,
    addOnData,
    reservationId,
    catId,
    selectedTravelers,
    travelers,
    loading,
    setLoading,
    searchParams
  } = props;
  const [column, setColumn] = useState();
  const navigate = useNavigate();
  let parentResUuid = searchParams.get("res-item-uuid");


  useEffect(() => {
    if (section === "inventory") {
      // getItemInventory()
      setColumn([
        {
          title: "Code",
          dataIndex: "code",
          key: "code",
        },

        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "Fees",
          dataIndex: "fees",
          key: "fees",
        },
        {
          title: "Discount",
          dataIndex: "discount",
          key: "discount",
        },
        {
          title: "Selling Price",
          dataIndex: "selling_price",
          key: "selling_price",
        },
        Table.EXPAND_COLUMN,
      ]);
    } else {
      setColumn([
        {
          title: "ID",
          dataIndex: "hash",
          key: "hash",
        },

        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        // Table.SELECTION_COLUMN,
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
        },

        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
        },
        Table.EXPAND_COLUMN,
      ]);
    }
  }, [section]);

  const addReservationItem = async (payload) => {
    setLoading(true)
    const response = await addItemApi(payload);
    if (response?.status) {
      message.success(response?.data?.response);
      navigate(`${ReservationDetailRoute}?id=${reservationId}`);
    } else {
      message.error(response?.data?.response?.data?.response || SOMETHING_WENT_WRONG);
    }
    setLoading(false)
  };

  const onSelect = (record, e) => {
    if (section === "inventory") {
      const payload = {
        data: {
          reservation_id: reservationId,
          category_id: catId,
          inventory_id: record?.uuid,
          inventory_hold_status: record?.field_machinename,
          parent_res_item: parentResUuid,
        },
      };
      let travelersId = [];
      selectedTravelers.forEach((element) => {
        travelersId.push({ id: element });
      });

      payload.data.travelers = travelersId;
      addReservationItem(payload);
    }
  };

  return (
    <>
      <Table
        className="reservation_expand__table"
        columns={column}
        // rowSelection={{}}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {section === "category" ? (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {record.description}
                </p>
              ) : null}
            </>
          ),
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          section === "category" ? (
            expanded ? (
              <Button type="primary" onClick={(e) => onExpand(record, e)}>
                Close <UpOutlined />
              </Button>
            ) : (
              <Button type="" onClick={(e) => onExpand(record, e)}>
                Expand
              </Button>
            )
          ) : (
            <Button type="" onClick={(e) => onSelect(record, e)}>
              Select
            </Button>
          )
        }
        // defaultExpandAllRows
        dataSource={addOnData}
        pagination={false}
        loading={{ spinning: loading }}
      />
    </>
  );
}

export default ReservationAddOn;
