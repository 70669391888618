import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Progress, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEventReservationApi } from "../../../../apis/eventsApi";
import { EventReservationsRoute } from "../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import "./EventsReservations.less";

const EventsReservations = ({ eventData, eventId, loading }) => {
  const [eventReservationData, setEventReservationData] = useState({});
  const [countLoading, setCountLoading] = useState(true);
  useEffect(() => {
    getEventReservation(eventId);
  }, []);
  const getEventReservation = async (id) => {
    setCountLoading(true);
    const response = await getEventReservationApi(id);
    if (response?.status) {
      setEventReservationData(response?.data?.data);
      setCountLoading(false);
    } else {
      setEventReservationData({});
      message.error(response?.data?.data?.message || SOMETHING_WENT_WRONG);
      setCountLoading(false);
    }
  };

  return (
    <>
      <div className="events_reservation">
        <Row>
          <Col xs={24} md={24} lg={24}>
            <div className="address_information">
              <h4>Reservations</h4>
            </div>
            <Divider className="global_divider" />
            <div className="site-card-wrapper">
              <Spin spinning={loading && countLoading} size="large">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Card className="card_bottom" bordered={true}>
                      <Link
                        to={`/event/details/reservation-details?id=${eventId}`}
                      >
                        <div className="card_icons">
                          {loading && countLoading ? null : (
                            <>
                              {" "}
                              <p className="card_paragraph">
                                {`${
                                  eventReservationData?.count_travelers || 0
                                }${
                                  eventReservationData?.maximum_traveler_capacity
                                    ? `/${eventReservationData?.maximum_traveler_capacity}`
                                    : ""
                                }`}

                                <span className="document_pdf"> Travelers</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </>
                          )}
                        </div>
                        {loading && countLoading ? null : (
                          <Progress
                            percent={
                              (eventReservationData?.count_travelers /
                                eventReservationData?.maximum_traveler_capacity) *
                              100
                            }
                            showInfo={false}
                          />
                        )}
                      </Link>
                    </Card>
                  </Col>

                  {loading && countLoading ? null : (
                    <>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=active`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.active_reservations || 0}{" "}
                                <span className="document_pdf">Active</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=revise`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.revise_reservations || 0}{" "}
                                <span className="document_pdf">Revise</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=temporary_hold`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.temp_hold_reservations ||
                                  0}{" "}
                                <span className="document_pdf">
                                  Temporary Hold
                                </span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=cancelled`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.cancelled_reservations ||
                                  0}{" "}
                                <span className="document_pdf">Canceled</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=quote`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.quotes_reservations || 0}{" "}
                                <span className="document_pdf">Quote</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=waitlist`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.waitlist_reservations ||
                                  0}{" "}
                                <span className="document_pdf">Waitlist</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=pending`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.pending_reservations ||
                                  0}{" "}
                                <span className="document_pdf">Pending</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=discarded`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.discarded_reservations ||
                                  0}{" "}
                                <span className="document_pdf">Discarded</span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=cancel_pending`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.cancelpending_reservations ||
                                  0}{" "}
                                <span className="document_pdf">
                                  {" "}
                                  Cancel Pending
                                </span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>{" "}
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Card className="card_bottom" bordered={true}>
                          <Link
                            to={`${EventReservationsRoute}?id=${eventId}&event=${eventData?.drupal_internal__id}&order=ASC&count=10&page=1&status=completed`}
                          >
                            <div className="card_icons">
                              <p className="card_paragraph">
                                {eventReservationData?.completed_reservations ||
                                  0}{" "}
                                <span className="document_pdf">
                                  {" "}
                                  Completed
                                </span>
                              </p>
                              <RightOutlined style={{ marginTop: "12px" }} />
                            </div>
                          </Link>
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
              </Spin>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EventsReservations;
