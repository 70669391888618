import {
  Table,
  Divider,
  Button,
  Tag,
  Collapse,
  message,
  Spin,
  Modal,
  Empty,
} from "antd";
import {
  ArrowRightOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Event_detailcategorysection from "../../../../View_components/Event_detailcategorysection/Event_detailcategorysection";
import {
  deleteAddonFlightApi,
  getFlightListingApi,
} from "../../../../../apis/eventsApi";
import moment from "moment";
import { AddonDetailsFlightRoute } from "../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { permissionChecker } from "../../../../../utils/permisssionChecker";
const { Panel } = Collapse;
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Departure",
    dataIndex: "departure",
    key: "departure",
  },
  {
    title: "Arrow",
    dataIndex: "arrow",
    key: "arrow",
  },
  {
    title: "Arrival",
    dataIndex: "arrival",
    key: "arrival",
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
  },
];

const AddonFlightListing = ({ eventId, addonId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFlightDeleted, setIsFlightDeleted] = useState(false);

  useEffect(() => {
    fetchFlightListing(addonId);
  }, [isFlightDeleted, addonId, eventId]);

  const fetchFlightListing = async (aId) => {
    setLoading(true);
    const response = await getFlightListingApi(aId);
    if (response.status) {
      setData(
        response?.data?.data?.length
          ? response?.data?.data?.map((list) => ({
              name: list?.flight_name,
              count: list?.segment_count,
              flightUuid: list?.flight_uuid,
              segment: list?.segment?.length
                ? list?.segment?.map((listItem) => ({
                    name: (
                      <div className="eventtable_flight">
                        <p>Flight No: {listItem.flight}</p>
                        <h1>{listItem.carrier}</h1>
                      </div>
                    ),
                    departure: (
                      <div className="eventtable_flight">
                        <p>Departure City / Date / Time</p>
                        <h1>
                          {`${
                            listItem?.departure_city
                              ? `${listItem?.departure_city} / `
                              : ""
                          }${
                            listItem?.departure_date
                              ? `${moment(listItem?.departure_date).format(
                                  "MM/DD/YYYY"
                                )} / `
                              : ""
                          }${
                            listItem?.departure_time
                              ? `${listItem?.departure_time} `
                              : ""
                          }`}{" "}
                        </h1>
                      </div>
                    ),
                    arrow: (
                      <div className="eventtable_flight">
                        <ArrowRightOutlined />
                      </div>
                    ),
                    arrival: (
                      <div className="eventtable_flight">
                        <p>Arrival City / Date / Time</p>
                        <h1>
                          {`${
                            listItem?.arrival_city
                              ? `${listItem?.arrival_city} / `
                              : ""
                          }${
                            listItem?.arrival_date
                              ? `${moment(listItem?.arrival_date).format(
                                  "MM/DD/YYYY"
                                )} / `
                              : ""
                          }${
                            listItem?.arrival_time
                              ? `${listItem?.arrival_time} `
                              : ""
                          }`}{" "}
                        </h1>
                      </div>
                    ),
                    tags: (
                      <div className="tag_btn">
                        {listItem?.departure ? <Tag>Departure</Tag> : null}
                        {listItem?.arrival ? <Tag>Arrival</Tag> : null}
                        {listItem?.print ? <Tag>Print</Tag> : null}
                        {listItem?.pre_ext_dep ? <Tag>Pre Ext Dep</Tag> : null}
                        {listItem?.pre_ext_arr ? <Tag>Pre Ext Arr</Tag> : null}
                        {listItem?.post_ext_dep ? (
                          <Tag>Post Ext Dep</Tag>
                        ) : null}
                        {listItem?.post_ext_arr ? (
                          <Tag>Post Ext Arr</Tag>
                        ) : null}
                      </div>
                    ),
                  }))
                : [],
              segmentCount: list.segment_count,
            }))
          : []
      );
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
      message.error(
        response?.data?.response?.data?.errors?.[0]?.title ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };
  const deleteEventDiscount = async (data) => {
    const response = await deleteAddonFlightApi(data);
    if (response.status) {
      message.success("Flight Deleted Successfully");
      setIsFlightDeleted(!isFlightDeleted);
    } else {
      message.error(
        response?.data?.response?.data?.message ||
          response?.data?.message ||
          SOMETHING_WENT_WRONG
      );
    }
  };

  const ondeleteHandler = (id) => {
    if (id) {
      Modal.confirm({
        title: "Are you sure you want to delete this flight?",
        okText: "Yes",
        okType: "danger",
        onOk: () => {
          const payload = {
            data: {
              type: "flights--flights",
              id,
            },
          };
          return deleteEventDiscount({ payload, flightId: id });
        },
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
  };
  return (
    <div className="requests flights-accordion">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="mb-0" style={{ fontFamily: "'Poppins'" }}>
          Flights
        </h4>

       {permissionChecker("add/edit addon") ?  <Link
          to={`${AddonDetailsFlightRoute}?id=${eventId}&addonId=${addonId}`}
        >
          <Button type="primary">
            <PlusOutlined /> Add
          </Button>
        </Link> : null}
      </div>{" "}
      <Divider className="global_divider" />
      <Spin spinning={loading} size="large">
        {data?.length ? (
          <Collapse
            collapsible="header"
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {data?.map((item, index) => (
              <Panel
                header={`${item.name} (${item?.count || 0})`}
                key={index}
                style={{
                  marginBottom: "20px",
                  background: "#f7f7f7",
                  border: "0px",
                  borderRadius: "2px",
                }}
                extra={
                  <span className="flights-anticon-icons">
                    {permissionChecker("add/edit addon") ? <Link
                      to={`${AddonDetailsFlightRoute}?id=${eventId}&addonId=${addonId}&editId=${item?.flightUuid}`}
                    >
                      <EditOutlined className="action_edit" />
                    </Link> : null}
                   {permissionChecker("delete addon") ?  <DeleteOutlined
                      className="action_delete"
                      onClick={(e) => {
                        e.preventDefault();
                        ondeleteHandler(item?.flightUuid);
                      }}
                    /> : null}
                  </span>
                }
              >
                <Table
                  className="event_cruise_table table_tour custom_para"
                  columns={columns}
                  dataSource={item?.segment?.length ? item?.segment : []}
                  showHeader={false}
                  pagination={false}
                />
              </Panel>
            ))}
          </Collapse>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};
export default AddonFlightListing;
