import { Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createInventoryApi,
  getAllCategoryListApi,
  getSingleInventoryApi,
  updateInventoryApi,
} from "../../../../../../apis/eventsApi";
import { getTaxonomyList } from "../../../../../../others/commonApiCalls/commonApiCalls";
import {
  AddonCategoryDetailsInventoryDetailRoute,
  EventCategoryDetailsInventoryDetailRoute,
} from "../../../../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../../../../utils/constants";
import "./Event_Viewinventory.less";
import InventoryItemInfoForm from "./InventoryForm/InventoryItemInfoForm";
import InventoryRoomInfoForm from "./InventoryForm/InventoryRoomInfoForm";

const CategoryInventoryInformation = ({
  form,
  loading,
  setLoading,
  loadingSingleInventory,
  setLoadingSingleInventory,
}) => {
  const navigate = useNavigate();
  const authorization = JSON.parse(localStorage.getItem("authorization"));
  const { uuid } = authorization;
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const eventId = searchParams.get("id");
  const addonId = searchParams.get("addonId");
  const inventoryId = searchParams.get("editId");

  const [deckNumber, setDeckNumber] = useState([]);
  const [loadingDeckNumber, setLoadingDeckNumber] = useState([]);

  const [view, setView] = useState([]);
  const [loadingView, setLoadingView] = useState(true);

  const [location, setLocation] = useState([]);
  const [loadingLocation, setLoadingLocation] = useState(true);

  const [category, setCategory] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(true);

  const [bed, setBed] = useState([]);
  const [loadingBed, setLoadingBed] = useState(true);

  const [extraBed, setExtraBed] = useState([]);
  const [loadingExtraBed, setLoadingExtraBed] = useState(true);

  const getAllCategory = async () => {
    setLoadingCategory(true);
    const response = await getAllCategoryListApi(addonId || eventId);
    if (response?.status) {
      setCategory(response?.data);
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setLoadingCategory(false);
  };

  useEffect(() => {
    getTaxonomyList("deck_number", setDeckNumber, setLoadingDeckNumber);
    getTaxonomyList("location", setLocation, setLoadingLocation);
    getTaxonomyList("view", setView, setLoadingView);
    getTaxonomyList("bed", setBed, setLoadingBed);
    getTaxonomyList("extra_bed", setExtraBed, setLoadingExtraBed);
    getAllCategory();
  }, []);

  function onSuccessRedirection(inventoryId) {
    let url = addonId
      ? `${AddonCategoryDetailsInventoryDetailRoute}?inventoryId=${inventoryId}&id=${eventId}&addonId=${addonId}&categoryId=${categoryId}`
      : `${EventCategoryDetailsInventoryDetailRoute}?inventoryId=${inventoryId}&id=${eventId}&categoryId=${categoryId}`;
    navigate(url);
  }

  const setRelationshipField = (fieldName, value, type) => {
    if (value) {
      return {
        [fieldName]: {
          data: {
            type: `taxonomy_term--${type}`,
            id: value,
          },
        },
      };
    } else {
      return {
        [fieldName]: {
          data: null,
        },
      };
    }
  };

  const onFinishHandler = (values) => {
    const {
      Accessible,
      Age,
      Balcony,
      Bed,
      Category,
      Connects,
      Deck,
      Location,
      Occupancy,
      Quantity,
      Smoking,
      Type,
      View,
      field_item_description,
      deckNumber,
      VendorCategoryCode,
      MinOccupancy,
      MaxShares,
      ItemName,
      ItemCode,
      field_extra_bed,
      Location2,
      Share,
    } = values;
    const payload = {
      data: {
        type: "category_inventory--category_inventory",

        attributes: {
          status: true,
          name: ItemName,
          field_item_number: ItemCode,
          field_quantity: Quantity,
          field_item_name: ItemName,
          field_occupancy: Occupancy,
          field_share: !!Share,
          field_max_shares: MaxShares,
          field_min_occupancy: MinOccupancy,
          field_item_description: field_item_description,
          field_location_2: Location2,
          field_balcony: !!Balcony,
          field_connects: Connects,
          field_deck: Deck,
          field_accessible: Accessible,
          field_organization_category_code: VendorCategoryCode,
          field_smoking: Smoking,
          field_age: Age,
          field_type: Type,
        },
        relationships: {
          field_category: {
            data: {
              type: "category--category",
              id: Category,
            },
          },

          modified_by: {
            data: uuid && {
              type: "user--user",
              id: uuid, // will getuser when login implemented
            },
          },
        },
      },
    };

    const relationships = {
      ...setRelationshipField("field_bed", Bed, "bed"),
      ...setRelationshipField("field_view", View, "view"),
      ...setRelationshipField("field_location", Location, "location"),
      ...setRelationshipField("field_deck_number", deckNumber, "deck_number"),
      ...setRelationshipField("field_extra_bed", field_extra_bed, "extra_bed"),
    };
    
    // Adding relationships to the payload
    payload.data.relationships = {
      ...payload.data.relationships,
      ...relationships,
    };

    setLoading(true);
    if (!inventoryId) {
      createInventory(payload);
    } else if (inventoryId) {
      payload.data.id = inventoryId;
      updateInventory(inventoryId, payload);
    }
  };
  const createInventory = async (payload) => {
    setLoading(true);
    const response = await createInventoryApi(payload);
    if (response.status) {
      message.success("Inventory Created Successfully");
      onSuccessRedirection(response?.data?.data?.id);
      setLoading(false);
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };
  const updateInventory = async (id, payload) => {
    setLoading(true);
    const response = await updateInventoryApi(id, payload);
    if (response.status) {
      message.success("Inventory Updated Successfully");
      onSuccessRedirection(response?.data?.data?.id);
      setLoading(false);
    } else {
      message.error(response?.data?.response?.data?.errors?.[0]?.detail || response?.data?.message || SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };
  const getSingleInventory = async () => {
    if (inventoryId && inventoryId !== "") {
      // setIsLoading(true);
      setLoadingSingleInventory(true);
      const response = await getSingleInventoryApi(inventoryId);
      if (response?.status) {
        const data = response?.data?.data;
        form.setFieldsValue({
          Accessible: data?.field_accessible,
          Age: data?.field_age,
          Balcony: data?.field_balcony,
          Bed: data?.field_bed?.id,
          Category: data?.field_category?.id,
          Connects: data?.field_connects,
          Deck: data?.field_deck,
          Location: data?.field_location?.id,
          Occupancy: data?.field_occupancy,
          Quantity: data?.field_quantity,
          Smoking: data?.field_smoking,
          Type: data?.field_type,
          View: data?.field_view?.id,
          field_item_description: data?.field_item_description,
          deckNumber: data?.field_deck_number?.id,
          VendorCategoryCode: data?.field_organization_category_code,
          MinOccupancy: data?.field_min_occupancy,
          MaxShares: data?.field_max_shares,
          ItemName: data?.field_item_name,
          ItemCode: data?.field_item_number,
          field_extra_bed: data?.field_extra_bed?.id,
          Location2: data?.field_location_2,
          Share: data?.field_share,
        });
        setLoadingSingleInventory(false);
        // setIsLoading(false);
      } else {
        message.error(SOMETHING_WENT_WRONG);
        setLoadingSingleInventory(false);
      }
    }
  };
  useEffect(() => {
    if (inventoryId) {
      getSingleInventory();
    }
  }, [inventoryId]);

  return (
    <>
      <Spin size="large" spinning={loadingSingleInventory}>
        <div className="details-tabs" style={{ margin: 0 }}>
          <div>
            <div className="basic_details pd-16 bg-white">
              <InventoryItemInfoForm
                onFinishHandler={onFinishHandler}
                loading={loading}
                form={form}
                deckNumber={deckNumber}
                loadingDeckNumber={loadingDeckNumber}
                loadingSingleInventory={loadingSingleInventory}
                category={category}
                loadingCategory={loadingCategory}
              />
            </div>
          </div>
          <div className="basic_details pd-16 bg-white">
            <InventoryRoomInfoForm
              onFinishHandler={onFinishHandler}
              loading={loading}
              form={form}
              view={view}
              loadingView={loadingView}
              bed={bed}
              loadingBed={loadingBed}
              extraBed={extraBed}
              loadingExtraBed={loadingExtraBed}
              location={location}
              loadingLocation={loadingLocation}
              loadingSingleInventory={loadingSingleInventory}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default CategoryInventoryInformation;
